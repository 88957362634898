import {
    NgModule
} from '@angular/core';
import {
    PreloadAllModules,
    RouterModule,
    Routes
} from '@angular/router';




const routes: Routes = [{
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
    },
    {
        path: 'approvalsetting',
        loadChildren: () => import('./approvalsetting/approvalsetting.module').then(m => m.ApprovalsettingPageModule)
    },
    {
        path: 'chdapproval',
        loadChildren: () => import('./chdapproval/chdapproval.module').then(m => m.ChdapprovalPageModule)
    },
    {
        path: 'approvalevent',
        loadChildren: () => import('./approvalevent/approvalevent.module').then(m => m.ApprovaleventPageModule)
    },
    {
        path: 'cancelapproval',
        loadChildren: () => import('./cancelapproval/cancelapproval.module').then(m => m.CancelapprovalPageModule)
    },
    {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserPageModule)
    },


    {
        path: 'season',
        loadChildren: () => import('./season/season.module').then(m => m.SeasonPageModule)
    },


    {
        path: 'sampletype',
        loadChildren: () => import('./sampletype/sampletype.module').then(m => m.SampletypePageModule)
    },


    {
        path: 'fabricinfo',
        loadChildren: () => import('./fabricinfo/fabricinfo.module').then(m => m.FabricinfoPageModule)
    },


    {
        path: 'componentfamily',
        loadChildren: () => import('./componentfamily/componentfamily.module').then(m => m.ComponentfamilyPageModule)
    },


    {
        path: 'components',
        loadChildren: () => import('./components/components.module').then(m => m.ComponentsPageModule)
    },

    {
        path: 'color',
        loadChildren: () => import('./color/color.module').then(m => m.ColorPageModule)
    },

 {
        path: 'reason',
        loadChildren: () => import('./reason/reason.module').then(m => m.ReasonPageModule)
    },

 {
        path: 'approvalevents',
        loadChildren: () => import('./approvalevents/approvalevents.module').then(m => m.ApprovaleventsPageModule)
    },

    {
        path: 'apilogs',
        loadChildren: () => import('./apilogs/apilogs.module').then(m => m.ApilogsPageModule)
    },

    {
        path: 'supplier',
        loadChildren: () => import('./supplier/supplier.module').then(m => m.SupplierPageModule)
    },
	
    {
        path: 'ordertype',
        loadChildren: () => import('./ordertype/ordertype.module').then(m => m.OrdertypePageModule)
    },
	
	 {
        path: 'quality',
        loadChildren: () => import('./quality/quality.module').then(m => m.QualityPageModule)
    },


    {
        path: 'poentrysample',
        loadChildren: () => import('./poentrysample/poentrysample.module').then(m => m.PoentrysamplePageModule)
    },


    {
        path: 'poentrybulkerp',
        loadChildren: () => import('./poentrybulkerp/poentrybulkerp.module').then(m => m.PoentrybulkerpPageModule)
    },


    {
        path: 'povalidationsample',
        loadChildren: () => import('./povalidationsample/povalidationsample.module').then(m => m.PovalidationsamplePageModule)
    },

    {
        path: 'poclosesample',
        loadChildren: () => import('./poclosesample/poclosesample.module').then(m => m.PoclosesamplePageModule)
    },


    {
        path: 'povalidationbulk',
        loadChildren: () => import('./povalidationbulk/povalidationbulk.module').then(m => m.PovalidationbulkPageModule)
    },

    

    {
        path: 'poclosebulk',
        loadChildren: () => import('./poclosebulk/poclosebulk.module').then(m => m.PoclosebulkPageModule)
    },


    {
        path: 'changepassword',
        loadChildren: () => import('./changepassword/changepassword.module').then(m => m.ChangepasswordPageModule)
    },

    {
        path: 'rolepermission',
        loadChildren: () => import('./rolepermission/rolepermission.module').then(m => m.RolepermissionPageModule)
    },
    {
        path: 'role',
        loadChildren: () => import('./role/role.module').then(m => m.RolePageModule)
    },
    {
        path: 'componentnature',
        loadChildren: () => import('./componentnature/componentnature.module').then(m => m.ComponentnaturePageModule)
    },
  
    {
        path: 'userpermission',
        loadChildren: () => import('./userpermission/userpermission.module').then(m => m.UserpermissionPageModule)
    },


    {
        path: 'datapermission',
        loadChildren: () => import('./datapermission/datapermission.module').then(m => m.DatapermissionPageModule)
    },
    {
        path: 'hotreports',
        loadChildren: () => import('./hotreports/hotreports.module').then(m => m.HotreportsPageModule)
    },
    {
        path: 'spl',
        loadChildren: () => import('./spl/spl.module').then(m => m.SplPageModule)
    },
    {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsPageModule)
    },
    {
        path: 'selection',
        loadChildren: () => import('./selection/selection.module').then(m => m.SelectionPageModule)
    },
        {
        path: 'qualitycomplaint',
        loadChildren: () => import('./qualitycomplaint/qualitycomplaint.module').then(m => m.QualitycomplaintPageModule)
    },
    {
        path: 'reasonrehd',
        loadChildren: () => import('./reasonrehd/reasonrehd.module').then(m => m.ReasonrehdPageModule)
    },
    {
        path: 'liftplan',
        loadChildren: () => import('./liftplan/liftplan.module').then(m => m.LiftplanPageModule)
    },
    {
        path: 'glossary',
        loadChildren: () => import('./glossary/glossary.module').then(m => m.GlossaryPageModule)
    },
    {
        path: 'alerts',
        loadChildren: () => import('./alerts/alerts.module').then(m => m.AlertsPageModule)
    },
    {
        path: 'reportsample',
        loadChildren: () => import('./reportsample/reportsample.module').then(m => m.ReportsamplePageModule)
    },


    {
        path: 'reportselection',
        loadChildren: () => import('./reportselection/reportselection.module').then(m => m.ReportselectionPageModule)
    },
	
    {
        path: 'reportehdpassed',
        loadChildren: () => import('./reportehdpassed/reportehdpassed.module').then(m => m.ReportehdpassedPageModule)
    },

    {
        path: 'tnaerp',
        loadChildren: () => import('./tnaerp/tnaerp.module').then(m => m.TnaerpPageModule)
    },
    {
        path: 'tnacustomdatechange',
        loadChildren: () => import('./tnacustomdatechange/tnacustomdatechange.module').then(m => m.TnacustomdatechangePageModule)
    },



  {
    path: 'perimeter',
    loadChildren: () => import('./perimeter/perimeter.module').then( m => m.PerimeterPageModule)
  },
  {
    path: 'pagepermission',
    loadChildren: () => import('./pagepermission/pagepermission.module').then( m => m.PagepermissionPageModule)
  },
  {
    path: 'tnaconfirm',
    loadChildren: () => import('./tnaconfirm/tnaconfirm.module').then(m => m.TnaconfirmPageModule)
},
  {
    path: 'componentdata',
    loadChildren: () => import('./componentdata/componentdata.module').then(m => m.ComponentdataPageModule)
},

{
    path: 'postatus',
    loadChildren: () => import('./postatus/postatus.module').then(m => m.PostatusPageModule)
},

{
    path: 'fgstockreport',
    loadChildren: () => import('./fgstockreport/fgstockreport.module').then(m => m.FgstockreportPageModule)
},

{
        path: 'buyershadedashbord',
        loadChildren: () => import('./buyershadedashbord/buyershadedashbord.module').then(m => m.BuyershadedashbordPageModule)
    },

   {
        path: 'productDevelopment',
        loadChildren: () => import('./productDevelopment/productDevelopment.module').then(m => m.ProductDevelopmentPageModule)
    },

    {
        path: 'proddevtracking',
        loadChildren: () => import('./proddevtracking/proddevtracking.module').then(m => m.ProddevtrackingPageModule)
    },
    
    {
        path: 'productsummarystatus',
        loadChildren: () => import('./productsummarystatus/productsummarystatus.module').then(m => m.ProductsummarystatusPageModule)
    },
     {
        path: 'productcatalog',
        loadChildren: () => import('./productcatalog/productcatalog.module').then(m => m.ProductcatalogPageModule)
    },
  {
    path: 'machineoccupancy',
    loadChildren: () => import('./machineoccupancy/machineoccupancy.module').then( m => m.MachineoccupancyPageModule)
  },
  {
    path: 'yarnrequirement',
    loadChildren: () => import('./yarnrequirement/yarnrequirement.module').then(m => m.YarnrequirementPageModule)
  },  
  {
    path: 'tnarealizationreport',
    loadChildren: () => import('./tnarealizationreport/tnarealizationreport.module').then(m => m.TnarealizationreportPageModule)
  },   {
    path: 'reportcomponent',
    loadChildren: () => import('./reportcomponent/reportcomponent.module').then( m => m.ReportcomponentPageModule)
  },


  
  {
    path: 'reportomfstatus',
    loadChildren: () => import('./reportomfstatus/reportomfstatus.module').then( m => m.ReportomfstatusPageModule)
  },
    
  {
    path: 'derogationform',
    loadChildren: () => import('./derogationform/derogationform.module').then( m => m.DerogationformPageModule)
  },
    
  {
    path: 'qualityroute',
    loadChildren: () => import('./qualityroute/qualityroute.module').then( m => m.QualityroutePageModule)
  },
    
     {
    path: 'operations',
    loadChildren: () => import('./operations/operations.module').then( m => m.OperationsPageModule)
  },
  {
    path: 'qualityroutetnaqty',
    loadChildren: () => import('./qualityroutetnaqty/qualityroutetnaqty.module').then( m => m.QualityroutetnaqtyPageModule)
  },

      {
    path: 'liftplandpo',
    loadChildren: () => import('./liftplandpo/liftplandpo.module').then( m => m.LiftplandpoPageModule)
  },


  {
    path: 'ecm',
    loadChildren: () => import('./ecm/ecm.module').then( m => m.EcmPageModule)
  },

  {
    path: 'adddpo',
    loadChildren: () => import('./adddpo/adddpo.module').then( m => m.AdddpoPageModule)
  },



];


@NgModule({
    imports: [
        // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })

        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload', 
  
        })



    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
